import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiLineSeparator, UiDiagonalSeparator, UiBubblesSeparator } from '../src/';
import { UiBadge } from '@uireact/badge';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <UiBadge category="warning" mdxType="UiBadge">❗️ Beta</UiBadge>
    <h1 {...{
      "id": "uiseparators"
    }}>{`UiSeparators`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/separator/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`A separator element to ease the change of colorations between blocks of content`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/separator`}</p>
    </blockquote>
    <h2 {...{
      "id": "uilineseparator"
    }}>{`UiLineSeparator`}</h2>
    <Playground __position={2} __code={'<>\n  <UiLineSeparator />\n  <br />\n  <UiLineSeparator inverse />\n  <br />\n  <UiLineSeparator delay={2000} />\n</>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiLineSeparator,
      UiDiagonalSeparator,
      UiBubblesSeparator,
      UiBadge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <>
    <UiLineSeparator mdxType="UiLineSeparator" />
    <br />
    <UiLineSeparator inverse mdxType="UiLineSeparator" />
    <br />
    <UiLineSeparator delay={2000} mdxType="UiLineSeparator" />
  </>
    </Playground>
    <h2 {...{
      "id": "uidiagonalseparator"
    }}>{`UiDiagonalSeparator`}</h2>
    <Playground __position={3} __code={'<UiDiagonalSeparator from=\"primary\" to=\"secondary\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiLineSeparator,
      UiDiagonalSeparator,
      UiBubblesSeparator,
      UiBadge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiDiagonalSeparator from="primary" to="secondary" mdxType="UiDiagonalSeparator" />
    </Playground>
    <h2 {...{
      "id": "uidiagonalseparator-inversed"
    }}>{`UiDiagonalSeparator inversed`}</h2>
    <Playground __position={4} __code={'<UiDiagonalSeparator from=\"primary\" to=\"secondary\" inverse />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiLineSeparator,
      UiDiagonalSeparator,
      UiBubblesSeparator,
      UiBadge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiDiagonalSeparator from="primary" to="secondary" inverse mdxType="UiDiagonalSeparator" />
    </Playground>
    <h2 {...{
      "id": "uibubblesseparator"
    }}>{`UiBubblesSeparator`}</h2>
    <Playground __position={5} __code={'<UiBubblesSeparator from=\"secondary\" to=\"primary\" />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiLineSeparator,
      UiDiagonalSeparator,
      UiBubblesSeparator,
      UiBadge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiBubblesSeparator from="secondary" to="primary" mdxType="UiBubblesSeparator" />
    </Playground>
    <h2 {...{
      "id": "uibubblesseparator-inversed"
    }}>{`UiBubblesSeparator inversed`}</h2>
    <Playground __position={6} __code={'<UiBubblesSeparator from=\"secondary\" to=\"primary\" inverse />'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiLineSeparator,
      UiDiagonalSeparator,
      UiBubblesSeparator,
      UiBadge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiBubblesSeparator from="secondary" to="primary" inverse mdxType="UiBubblesSeparator" />
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiBubblesSeparator} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      